import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Account from './screens/account';
import Feedback from './screens/feedback';
import ReferAFriend from './screens/refer-a-friend';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import ApplyVouchers from './screens/applyVouchers';

// import AllergensInfo from './screens/allergensInfo'
import OrderToTable from './screens/orderToTable';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import { ServiceCharge } from './screens/service-charge';
import GiftVouchers from './screens/giftVouchers';
import ApplyLoyalty from './screens/applyLoyalty';
import MyMembership from './screens/myMembership';
import MembershipCompleted from './screens/membershipCompleted';
import CheckoutMembership from './screens/checkoutMembership';
import CardAddMembersip from './screens/cardAddMembership';
import ChooseSubscriptionMenu from './screens/chooseSubscriptionMenu';
import ChooseSubscription from './screens/chooseSubscription';
import TermsAndConditions from './screens/terms-subscription-package';
import api from './lib/api';
import CreateAccount from './screens/createAccount';
import PayTableBill from './screens/payTableBill';
import PayTableOverview from './screens/payTableOverview';
import SplitBill from './screens/splitBill';
import SplitBillByAmount from './screens/splitBillByAmount';
import SplitBillByItems from './screens/splitBillByItems';
import BillCompleted from './screens/billCompleted';
import PickUpAtCounter from './screens/PickUpAtCounter';
import BrowseMenu from './screens/browseMenu';
import SentGiftVouchers from './screens/sentGiftVouchers';
import GiftVoucherOverview from './screens/giftVoucherOverview';
import GiftBalance from './screens/giftBalance';
import ScheduledDelivery from './screens/scheduled-delivery';
import ScheduledDeliveryTime from './screens/scheduled-delivery-time';
import nativeOrderStatus from './components/nativeOrderStatus';
import errorPage from './screens/errorPage';

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

const home = require('./assets/images/home.svg');
const loyalty = require('./assets/images/loyalty.svg');
const myAccount = require('./assets/images/account.svg');
const feedback = require('./assets/images/feedback.svg');
const referAFriend = require('./assets/images/refer.svg');
const history = require('./assets/images/history.svg');
const locations = require('./assets/images/locations.svg');
const social = require('./assets/images/social-media.svg');
const login = require('./assets/images/login.svg');
const logout = require('./assets/images/logout.svg');
const settings = require('./assets/images/settings.svg');
const startNewOrder = require('./assets/images/order.svg');
const menu = require('./assets/images/menu.svg');
const terms = require('./assets/images/terms.svg');
const privacy = require('./assets/images/privacy.svg');
const qm = require('./assets/images/qm.svg');
const gift = require('./assets/images/gift.svg');

let navConfig;

export const initRouter = async (config) => {
	const singleDeliveryOption = await getSingleDeliveryOption(config);
	const { hasBillPay, hasGiftVouchers, hasMembership, hasGuestCheckout, hasReferAFriend, hasOrdering, hasLoyalty, removeLocations, removeSocial } = config.front_end_app_config.flags;
	const { defaultRoute, defaultMobileRoute } = config.front_end_app_config.general;
	const customFeedbackLink = config.front_end_app_config?.general?.customFeedbackLink || false;
	const availableOrderModules = config.front_end_app_config?.delivery.filter((d) => !d.isRemoved && !d.isDisabled).map((el) => el.route);
	navConfig = {
		routes: [
			isWebConfig()
				? []
				: {
						label: 'Home',
						path: '/dashboard',
						component: Dashboard,
						icon: home,
						exact: true,
						default: !defaultMobileRoute,
				  },
			...(hasOrdering
				? [
						{
							label: 'Menu',
							path: '/order',
							component: Order,
							icon: menu,
							exact: true,
							default: isWebConfig() && hasOrdering && !defaultRoute,
							group: 'button',
						},
				  ]
				: []),
			...(hasBillPay
				? [
						{
							icon: menu,
							label: 'Pay your bill',
							path: '/table-bill-pay',
							component: PayTableBill,
							protected: false,
						},
				  ]
				: []),

			{
				label: 'Apply Loyalty',
				path: '/apply-loyalty',
				component: ApplyLoyalty,
				icon: menu,
				protected: true,
				notInDrawer: true,
			},
			...(hasMembership
				? [
						{
							label: 'Membership',
							path: '/get-membership',
							component: ChooseSubscriptionMenu,
							icon: menu,
							protected: true,
							group: 2,
							default: (isWebConfig() && defaultRoute === 'membership') || defaultMobileRoute === 'membership',
							selectedUrlKeyword: 'membership',
						},
						{
							label: 'Membership',
							path: '/membership',
							component: ChooseSubscription,
							icon: home,
							notInDrawer: true,
							protected: true,
							selectedUrlKeyword: 'membership',
						},
				  ]
				: []),
			{
				label: 'TermsAndConditions',
				path: '/membership-terms',
				component: TermsAndConditions,
				notInDrawer: true,
				protected: true,
			},
			{
				label: 'Checkout Membership',
				path: '/checkout-membership',
				component: CheckoutMembership,
				notInDrawer: true,
				protected: true,
			},
			{
				label: 'MyMembership',
				path: '/my-membership',
				component: MyMembership,
				notInDrawer: true,
				protected: true,
			},
			{
				label: 'Membership Completed',
				path: '/membership-completed',
				component: MembershipCompleted,
				notInDrawer: true,
				protected: false,
			},
			{
				label: 'Add card Membership',
				path: '/add-card-membership',
				component: CardAddMembersip,
				notInDrawer: true,
				protected: true,
			},
			...(hasOrdering
				? [
						availableOrderModules.includes('/click-and-collect') && {
							label: singleDeliveryOption ? singleDeliveryOption.label : '',
							path: '/click-and-collect',
							component: ClickAndCollect,
							icon: settings,
							protected: false,
							notInDrawer: !singleDeliveryOption,
						},
						availableOrderModules.includes('/click-and-collect-scheduled') && {
							label: singleDeliveryOption ? singleDeliveryOption.label : '',
							path: '/click-and-collect-scheduled',
							component: ClickAndCollect,
							icon: settings,
							protected: false,
							notInDrawer: !singleDeliveryOption,
							scheduled: true,
						},
				  ]
				: []),
			...(hasOrdering
				? [
						{
							label: 'Start New Order',
							path: '/delivery-options',
							component: DeliveryOptions,
							icon: startNewOrder,
							protected: false,
							notInDrawer: !!singleDeliveryOption,
						},
				  ]
				: []),
			...(hasLoyalty
				? [
						{
							label: 'Loyalty',
							path: '/loyalty',
							component: Loyalty,
							icon: loyalty,
							protected: true,
							default: (isWebConfig() && defaultRoute === 'loyalty') || defaultMobileRoute === 'loyalty',
							state: { tab: 'points' },
							group: 1,
						},
				  ]
				: []),
			...(hasReferAFriend
				? [
						{
							label: 'Refer a friend',
							path: '/refer-a-friend',
							component: ReferAFriend,
							icon: referAFriend,
							protected: true,
							group: 1,
						},
				  ]
				: []),
			...(hasOrdering || hasGiftVouchers || hasMembership
				? [
						{
							label: 'Checkout',
							path: '/checkout',
							component: Checkout,
							notInDrawer: true,
							protected: !hasGuestCheckout,
						},
				  ]
				: []),
			...(hasOrdering
				? [
						{
							label: 'Apply Points',
							path: '/apply-points',
							component: ApplyPoints,
							notInDrawer: true,
							protected: true,
						},
				  ]
				: []),
			...(hasOrdering
				? [
						{
							label: 'Service Charge',
							path: '/service-charge',
							component: ServiceCharge,
							notInDrawer: true,
							protected: true,
						},
				  ]
				: []),
			{
				label: 'My account',
				path: '/account',
				component: Account,
				icon: myAccount,
				protected: true,
				group: 2,
			},
			...(hasGiftVouchers
				? [
						{
							label: 'Send a voucher',
							path: '/gift-vouchers',
							component: GiftVouchers,
							icon: gift,
							protected: false,
							group: 4,
							default: (isWebConfig() && defaultRoute === 'gift-vouchers') || defaultMobileRoute === 'gift-vouchers',
						},
						{
							label: 'Check balance',
							path: '/voucher-balance',
							component: GiftBalance,
							protected: false,
							icon: gift,
							group: 4,
						},
						{
							label: 'Sent vouchers',
							path: '/sent-vouchers',
							component: SentGiftVouchers,
							icon: gift,
							protected: true,
							group: 4,
						},
						{
							path: '/gift-voucher',
							component: GiftVoucherOverview,
							icon: gift,
							protected: false,
							notInDrawer: true,
						},
				  ]
				: []),
			{
				label: 'History',
				path: '/history',
				component: History,
				icon: history,
				protected: true,
				group: 2,
			},
			...(customFeedbackLink
				? [
						{
							label: 'Feedback',
							component: Feedback,
							icon: feedback,
							protected: true,
							group: 2,
							isLink: true,
							path: customFeedbackLink,
						},
				  ]
				: [
						{
							label: 'Leave feedback',
							path: '/feedback',
							component: Feedback,
							icon: feedback,
							protected: true,
							group: 2,
						},
				  ]),

			...(!removeLocations
				? [
						{
							label: 'Locations',
							path: '/locations',
							component: Locations,
							icon: locations,
							protected: false,
							group: 3,
						},
				  ]
				: []),
			...(!removeSocial
				? [
						{
							label: 'Social media',
							path: '/social',
							component: Social,
							icon: social,
							protected: false,
							group: 3,
						},
				  ]
				: []),

			{
				label: 'Contact Details',
				path: '/contact-details',
				component: ContactDetails,
				protected: !hasGuestCheckout,
				notInDrawer: true,
			},
			{
				label: 'Apply Vouchers',
				path: '/apply-vouchers',
				component: ApplyVouchers,
				notInDrawer: true,
				protected: true,
			},
			{
				label: 'FAQ',
				path: '/faq',
				component: Faq,
				icon: qm,
				protected: false,
				notInDrawer: false,
				group: 3,
			},
		],
		authRoutes: [
			{ label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
			{ label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
		],
		additionalRoutes: [
			{ label: 'T&Cs', path: '/terms', component: Terms, icon: terms },
			{ label: 'Privacy Policy', path: '/privacy', component: Privacy, icon: privacy },
		],
		notInMenuRoutes: [
			{ path: '/error', component: errorPage },

			{ path: '/register', component: Register },
			{ path: '/create-account', component: CreateAccount },
			{ path: '/reset-password', component: ResetPassword },
			...(hasOrdering ? [{ path: '/order', component: Order }] : []),
			...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
			...(hasOrdering || hasGiftVouchers || hasMembership ? [{ path: '/cards', component: Cards }] : []),
			...(hasOrdering || hasGiftVouchers || hasMembership ? [{ path: '/card-add', component: CardAdd }] : []),
			...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
			...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
			...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
			...(hasOrdering ? [{ path: '/order-status', component: nativeOrderStatus }] : []),
			...(hasOrdering && availableOrderModules.includes('/order-to-table') ? [{ path: '/order-to-table', component: OrderToTable }] : []),
			...(hasOrdering && availableOrderModules.includes('/delivery') ? [{ path: '/delivery', component: Delivery }] : []),
			...(hasOrdering && availableOrderModules.includes('/scheduled-delivery') ? [{ path: '/scheduled-delivery', component: ScheduledDelivery }] : []),
			...(hasOrdering ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }] : []),
			...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
			...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
			...(hasOrdering ? [{ path: '/scheduled-delivery-time', component: ScheduledDeliveryTime }] : []),
			...(hasOrdering ? [{ path: '/table-overview', component: PayTableOverview, protected: false }] : []),
			...(hasOrdering ? [{ path: '/split-bill', component: SplitBill, protected: false }] : []),
			...(hasOrdering ? [{ path: '/split-bill-by-amount', component: SplitBillByAmount, protected: false }] : []),
			...(hasOrdering ? [{ path: '/split-bill-by-items', component: SplitBillByItems, protected: false }] : []),
			...(hasOrdering ? [{ path: '/bill-completed', component: BillCompleted, protected: false }] : []),
			...(hasOrdering && availableOrderModules.includes('/pick-up-at-counter') ? [{ path: '/pick-up-at-counter', component: PickUpAtCounter, protected: false }] : []),
			...(hasOrdering && availableOrderModules.includes('/browse-menu') ? [{ path: '/browse-menu', component: BrowseMenu }] : []),
		],
	};

	return navConfig;
};

export default navConfig;
